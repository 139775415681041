import { createTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    // palette values for dark mode
    // primary: obtainColor(primaryString) || amber,
    // secondary: obtainColor(secondaryString) || pink,
    success: green,
    // text: {
    //   primary: "#f5f5f5",
    //   secondary: grey[500],
    // },
  },
  button: {
    textAlign: "center",
    width: "100%",
  },
});

export default theme;

// palette: {
//   mode: mode || "light",
//   ...(mode === "light"
//     ? {
//         // palette values for light mode
//         primary: obtainColor(primaryString) || red,
//         secondary: obtainColor(secondaryString) || amber,
//         text: {
//           primary: grey[900],
//           secondary: grey[800],
//         },
//       }
//     : {
//         // palette values for dark mode
//         primary: obtainColor(primaryString) || amber,
//         secondary: obtainColor(secondaryString) || pink,
//         text: {
//           primary: "#f5f5f5",
//           secondary: grey[500],
//         },
//       }),
// },
