import { client } from "../src/util/apollo";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./util/theme";
import CssBaseline from "@material-ui/core/CssBaseline";

import { ApolloProvider } from "@apollo/client";
import { BaseRouter } from "./routers/BaseRouter";
import { UserProvider } from "./providers/UserProvider";

function App({ currentUser = defaultUser, tweets = defaultTweets }) {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <BaseRouter />
        </UserProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

const defaultUser = {
  id: "user1",
  username: "johndoe",
  full_name: "John Doe",
  avatar_url:
    "https://material-ui-1dab0.firebaseapp.com/build/fa158bc2d4774f4ae14cbbec7730af23.jpg",
};

const defaultTweets = [
  {
    id: "tweet1",
    body: "Lorem Ipsum",
    date: new Date(2021, 1, 1),
    Author: {
      id: "author1",
      username: "johndoe",
      full_name: "John Doe",
      avatar_url:
        "https://material-ui-1dab0.firebaseapp.com/build/fa158bc2d4774f4ae14cbbec7730af23.jpg",
    },
    Stats: { views: 98, likes: 42, retweets: 218, responses: 7 },
  },
  {
    id: "tweet2",
    body: "Sic dolor amet",
    date: new Date(),
    Author: {
      id: "author2",
      username: "janedoe",
      full_name: "Jane Doe",
      avatar_url:
        "https://material-ui-1dab0.firebaseapp.com/build/b16427bb030d63fd8e52ea84defda1d1.jpg",
    },
    Stats: { views: 98, likes: 42, retweets: 218, responses: 7 },
  },
];

export default App;
