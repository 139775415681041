// start firebase
import firebase from "firebase";

// Configure Firebase.
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyACSWNBjutDe_5-KdmC5_wHYh9CK9WGcJg",
  authDomain: "sonorous-shore-313203.firebaseapp.com",
  projectId: "sonorous-shore-313203",
  storageBucket: "sonorous-shore-313203.appspot.com",
  messagingSenderId: "540215125904",
  appId: "1:540215125904:web:bc63d212cbde3dcc9ff515",
  measurementId: "G-Q4D4FWGJ92",
};
firebase.initializeApp(firebaseConfig);

export const firebaseInstance = firebase;
