import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CachedIcon from "@material-ui/icons/Cached";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";

import { formatDistance } from "date-fns";
import { gql, useMutation } from "@apollo/client";

import { UserContext } from "../../src/providers/UserProvider";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: "center",
  },
  card: {
    margin: "1em auto",
    borderRadius: "17px",
    display: "inline-block",
    width: "70%",
  },
  link: {
    textDecoration: "none",
  },
  container: {
    display: "inline-flex",
    flexDirection: "row",
  },
  avatar: {
    marginRight: "0.5rem",
  },
  linkText: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    marginRight: "0.5rem",
  },
  userName: {
    color: theme.palette.text.secondary,
    marginRight: "0.5rem",
  },
  separator: {
    color: theme.palette.text.secondary,
    marginRight: "0.5rem",
  },
  date: {
    color: theme.palette.text.secondary,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  icon: {
    marginRight: "0.5rem",
  },
  stats: {
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  updateIconButton: {
    marginLeft: "1em",
    flex: "1",
    maxHeight: "50px",
    margin: "auto",
  },
}));

const Link = ({
  link: fetchedLink,
  isNew = false,
  refetchQuery = "GetAllLinks",
  deleteCallbackThunk,
  userFavoriteId = null,
  communityLinkId = null,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    updating: isNew,
    creating: isNew,
    link: fetchedLink,
  });

  const [currentUser, setUserContext] = useContext(UserContext);
  const location = useLocation();
  const { updating, creating, link } = state;

  const setLinkText = ({ target: { value: newLinkText } }) =>
    setState({
      ...state,
      link: {
        ...link,
        linkText: newLinkText,
      },
    });

  const setLinkDescription = ({ target: { value: newLinkDesc } }) =>
    setState({
      ...state,
      link: {
        ...link,
        description: newLinkDesc,
      },
    });

  const setLinkUrl = ({ target: { value: newUrl } }) =>
    setState({
      ...state,
      link: {
        ...link,
        url: newUrl,
      },
    });

  const [
    updateLink,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(
    gql`
      mutation UpdateLink($link: LinkInput!) {
        updateLink(link: $link) {
          id
          linkText
          url
          updated
        }
      }
    `
  );

  const [
    createLink,
    { loading: createLoading, error: createError, data: createData },
  ] = useMutation(
    gql`
      mutation CreateLink(
        $url: String!
        $linkText: String
        $userFavoriteId: ID
        $communityLinkId: ID
      ) {
        createLink(
          url: $url
          linkText: $linkText
          userFavoriteId: $userFavoriteId
          communityLinkId: $communityLinkId
        ) {
          id
          linkText
          url
          updated
        }
      }
    `,
    {
      refetchQueries: ["GetCurrentUser", refetchQuery],
      onCompleted: (_data) => deleteCallbackThunk(link.id)(),
    }
  );

  const toggleUpdating = (e) => {
    e.stopPropagation();
    setState((currentState) => ({
      ...currentState,
      updating: !currentState.updating,
    }));
  };

  const toggleUpdatingAndSave = (e) => {
    //useMutation here for saving or creating

    if (creating) {
      createLink({
        variables: {
          url: link.url,
          linkText: link.linkText,
          userFavoriteId,
          communityLinkId,
        },
      });
    } else {
      updateLink({
        variables: {
          link: {
            id: link.id,
            url: link.url,
            linkText: link.linkText,
            updated: link.updated,
          },
        },
      });
    }
    toggleUpdating(e);
  };

  const isFavorite = () => currentUser.favoriteLinks.includes(link.id);

  const addFavorite = (e) => {
    e.stopPropagation();
    addFavoriteMutation();
  };

  const [addFavoriteMutation] = useMutation(
    gql`
      mutation AddFavoriteLink($linkId: ID!) {
        addFavoriteLink(linkId: $linkId) {
          id
          favoriteLinks
          updated
        }
      }
    `,
    {
      variables: {
        linkId: link.id,
      },
      onError: (error) => {
        throw new Error(
          `Error in Link > AddFavoriteLink mutation: ${error.message}`
        );
      },
      onCompleted: ({ addFavoriteLink: updatedUser }) => {
        setUserContext({
          currentUser: {
            ...currentUser,
            ...updatedUser,
          },
        });
      },
    }
  );

  const removeFavorite = (e) => {
    e.stopPropagation();
    removeFavoriteMutation();
  };

  const [removeFavoriteMutation] = useMutation(
    gql`
      mutation RemoveFavoriteLink($linkId: ID!) {
        removeFavoriteLink(linkId: $linkId) {
          id
          favoriteLinks
          updated
        }
      }
    `,
    {
      variables: {
        linkId: link.id,
      },
      onError: (error) => {
        throw new Error(
          `Error in Link > RemoveFavoriteLink mutation: ${error.message}`
        );
      },
      onCompleted: ({ removeFavoriteLink: updatedUser }) => {
        setUserContext({
          currentUser: {
            ...currentUser,
            ...updatedUser,
          },
        });
      },
    }
  );

  return (
    link && (
      // <a href={link.url} target="_blank">
      <div className={classes.wrapper}>
        <Card
          onClick={() => {
            !updating && !creating && link.url && window.open(link.url);
          }}
          className={classes.card}
        >
          <CardContent className={classes.container}>
            {/* <div className={classes.container}> */}
            <Avatar
              className={classes.avatar}
              alt={link.linkText.toUpperCase()}
              src={link.linkText.toUpperCase()}
            />
            <div>
              <div>
                {updating ? (
                  <TextField
                    // style={{ padding: 24 }}
                    id={`linkText-${link.id}`}
                    {...(link.linkText
                      ? { defaultValue: link.linkText }
                      : { placeholder: "Link Name" })}
                    margin="normal"
                    onBlur={setLinkText}
                  />
                ) : (
                  <>
                    <span className={classes.linkText}>{link.linkText}</span>
                    {/* <span className={classes.userName}>
                      {tweet.Author.username}
                    </span>
                    <span className={classes.separator}> - </span> */}
                    <span className={classes.date}>
                      {`updated ${formatDistance(
                        new Date(link.updated),
                        new Date(),
                        {
                          addSuffix: true,
                        }
                      )}`}
                    </span>
                  </>
                )}
              </div>
              <div>
                {updating && (
                  <TextField
                    // style={{ padding: 24 }}
                    id={`linkUrl-${link.id}`}
                    {...(link.url
                      ? { defaultValue: link.url }
                      : { placeholder: "URL" })}
                    margin="normal"
                    onBlur={setLinkUrl}
                  />
                )}
              </div>
              <div>
                {updating ? (
                  <TextField
                    // style={{ padding: 24 }}
                    id={`linkDesc-${link.id}`}
                    {...(link.description
                      ? { defaultValue: link.description }
                      : { placeholder: "Description" })}
                    margin="normal"
                    onBlur={setLinkDescription}
                  />
                ) : (
                  <Typography component="p">{link.description}</Typography>
                )}
              </div>

              <div className={classes.buttons}>
                {/* <div className={classes.stats}>
                  <ChatBubbleOutlineIcon className={classes.icon} />
                  {tweet.Stats.responses}
                </div>
                <div className={classes.stats}>
                  <CachedIcon className={classes.icon} />
                  {tweet.Stats.retweets}
                </div> */}
                <div className={classes.stats}>
                  {isFavorite() ? (
                    <FavoriteIcon
                      className={classes.icon}
                      style={{ color: "red" }}
                      onClick={removeFavorite}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      className={classes.icon}
                      onClick={addFavorite}
                    />
                  )}

                  {/* {tweet.Stats.likes} */}
                </div>
              </div>
            </div>
            <div
              height={1}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {updating ? (
                <div className={classes.updateIconButton}>
                  <IconButton onClick={toggleUpdatingAndSave}>
                    <SaveIcon />
                  </IconButton>

                  <IconButton onClick={deleteCallbackThunk(link.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ) : (
                <IconButton
                  className={classes.updateIconButton}
                  onClick={toggleUpdating}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
      // </a>
    )
  );
};

export default Link;
