//React and Apollo gql dependencies
import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

//material UI dependencies
import { makeStyles } from "@material-ui/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

//local imports
import { NewSiteStepper } from "./NewSiteStepper";
import { defaultSiteTemplateID } from "../util/defaults";

//other dependencies
import { v4 as uuidv4 } from "uuid";
import { LinearProgress } from "@material-ui/core";

const formWidth = "40%";
const formMinWidth = "270px";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "1rem",
  },
  textarea: {
    width: "100%",
    resize: "none",
    boxSizing: "border-box",
  },
  addIcon: {
    fontSize: 40,
    margin: "0 auto",
  },
  iconWrapper: {
    textAlign: "center",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: theme.spacing(5, "auto"),
    minHeight: "3em",
    width: formWidth,
    minWidth: formMinWidth,
    borderRadius: "10px",
  },
  chip: {
    margin: theme.spacing(0.5),
    height: "2.5em",
  },
  buttonWrapper: {
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  button: {
    justifyContent: "center",
    display: "inline-block",
    margin: theme.spacing(1),
  },
  inputWrapper: {
    width: "100%",
    textAlign: "center",
  },
  input: {
    margin: theme.spacing(2),
    width: formWidth,
    minWidth: formMinWidth,
  },
  listWrapper: {
    width: "100%",
    textAlign: "center",
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    width: formWidth,
    minWidth: formMinWidth,
    margin: "auto",
    borderRadius: "10px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalWindow: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const NewSiteModal = ({
  modalOpen = false,
  setModalOpen = () => {},
  siteTemplateID = defaultSiteTemplateID,
}) => {
  const classes = useStyles();
  //   const [filter, setFilter] = useState("");
  const [newSubdomain, setNewSubdomain] = useState("");

  const isValidUrl = (event) => {
    if (event.charCode !== 13) {
      return;
    }

    const url = event.target.value;

    return true;
  };

  const handleSnackbarClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNewSubdomain("");
  };

  const handleModalClose = () => {
    setModalOpen(false);
    // setFilter("");
  };

  const siteStepperCallback = (newSubdomain) => {
    handleModalClose();
    if (typeof newSubdomain === "string") {
      setNewSubdomain(newSubdomain.toLowerCase());
    }
  };

  //   if (allCommunitiesQueryError)
  //     return `Error loading all communities: ${allCommunitiesQueryError.message}`;

  return (
    <>
      {/* {allCommunitiesQueryLoading && <LinearProgress />} */}
      {/* {!allCommunitiesQueryLoading && ( */}
      <>
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={modalOpen}
            onClose={handleModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={modalOpen}>
              <div className={classes.modalWindow}>
                <NewSiteStepper
                  siteTemplateID={siteTemplateID}
                  callback={siteStepperCallback}
                />
              </div>
            </Fade>
          </Modal>
        </div>
        <Snackbar
          open={newSubdomain}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            onClose={handleSnackbarClose}
            elevation={6}
            variant="filled"
            severity="info"
          >
            {newSubdomain && (
              <span>
                {`Congrats!! Your new site will be available at `}
                <a href={`https://osdl.io/${newSubdomain}`}>
                  osdl.io/{newSubdomain}
                </a>
                {` in minutes!!`}
              </span>
            )}
            {!newSubdomain &&
              `Looks like you didn't pick a domain for your site`}
          </MuiAlert>
        </Snackbar>
      </>
      {/* )} */}
    </>
  );
};
