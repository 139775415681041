//react imports
import React, { useContext } from "react";

//other imports
import { BrowserRouter as Router } from "react-router-dom";

//local imports
import { UserContext } from "../providers/UserProvider";
import { Header } from "../components/Header";
import { LoginScreen } from "../screens/LoginScreen";
import { LinksRouter } from "./LinksRouter";
import { SitesRouter } from "./SitesRouter";
import { EducationRouter } from "./EducationRouter";

export const BaseRouter = () => {
  const [currentUser] = useContext(UserContext);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  return (
    <Router>
      {currentUser ? (
        <div>
          <Header currentUser={currentUser} />
          {baseUrl === process.env.REACT_APP_HOME_BASE_URL && <LinksRouter />}
          {baseUrl === process.env.REACT_APP_LINKS_BASE_URL && <LinksRouter />}
          {baseUrl === process.env.REACT_APP_SITES_BASE_URL && <SitesRouter />}
          {baseUrl === process.env.REACT_APP_EDUCATION_BASE_URL && (
            <EducationRouter />
          )}
        </div>
      ) : (
        <LoginScreen />
      )}
    </Router>
  );
};
