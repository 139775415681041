import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

//TODO...make modular based on numCols
export const LoadingRow = ({ numCols = 1, dense = false }) => {
  return (
    <TableRow
      style={{
        height: dense ? 33 : 53,
      }}
    >
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
};
