//React and Apollo gql dependencies
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

//local imports
import { ScreenTemplate } from "../components/ScreenTemplate";
import { SiteCard } from "../components/SiteCard";
import { NewSiteModal } from "../components/NewSiteModal";

//images for cards
import ResumeDraw from "../assets/img/resume-draw.svg";
import BusinessBasicsDraw from "../assets/img/business-basics-draw.svg";
import SimpleCleanDraw from "../assets/img/simple-clean-draw.svg";

//defaults
import { defaultSiteTemplateID } from "../util/defaults";

const useStyles = makeStyles((theme) => {
  return {};
});

const ctaAction = () => {
  return true;
};

export const NewSiteScreen = () => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [siteTemplateID, setSiteTemplateID] = useState(defaultSiteTemplateID);

  const openModal =
    (newSiteTemplateID = defaultSiteTemplateID) =>
    () => {
      setModalOpen(true);
      setSiteTemplateID(newSiteTemplateID);
    };

  return (
    <>
      <NewSiteModal
        siteTemplateID={siteTemplateID}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <ScreenTemplate
        title="Create a New Site"
        subtitle="Select a template and launching will commence!"
        newSiteCta={false}
      >
        <div style={{ textAlign: "center" }}>
          <SiteCard
            newSiteLaunch
            title="Daily Business"
            subheader="Beautifully crafted."
            description="A clean, responsive template for a professional business website."
            demoUrl="https://general-template.opensites.io/"
            srcImg={BusinessBasicsDraw}
            srcAlt="Website Drawing"
            ctaAction={openModal("935a2756-4808-11ed-a388-672eb9344e2d")}
          />
          <SiteCard
            newSiteLaunch
            title="Modern Resume"
            subheader="Brand your background."
            description="Launch a beautiful, professional, web-enabled resume in seconds."
            demoUrl="https://resume-template.opensites.io/"
            srcImg={ResumeDraw}
            srcAlt="Resume Drawing"
            ctaAction={openModal("37fece92-4809-11ed-bb8a-1bed380d1a59")}
          />
          <SiteCard
            newSiteLaunch
            title="Basic Bookmarks"
            subheader="Simple & Clean."
            description="A clean template for a professional bookmarks page."
            demoUrl="https://bookmarks-template.opensites.io/"
            srcImg={SimpleCleanDraw}
            srcAlt="Website Drawing"
            ctaAction={openModal("a6d1fac4-4809-11ed-8d60-b72be3078296")}
          />
          <SiteCard
            newSiteLaunch
            title="Tailwind Blog"
            subheader="Responsive, modern blog theme."
            description="Elegant Blog template using Tailwind CSS.  Derived from this project: https://github.com/timlrx/tailwind-nextjs-starter-blog."
            demoUrl="https://bookmarks-template.opensites.io/"
            srcImg={SimpleCleanDraw}
            srcAlt="Website Drawing"
            ctaAction={openModal("e5207a6c-48d6-11ed-917c-8f9bfc444287")}
          />
        </div>
      </ScreenTemplate>
    </>
  );
};
