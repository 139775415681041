//React and Apollo gql dependencies
import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";

//material UI dependencies
import LinearProgress from "@material-ui/core/LinearProgress";

//local project dependencies
import { UserContext } from "../../src/providers/UserProvider";
import LinksPage from "../../src/components/LinksPage";

const UserLinksPage = () => {
  const [currentUser] = useContext(UserContext);

  const { loading, error, data } = useQuery(
    gql`
      query GetFavoriteLinks($userId: ID!) {
        getFavoriteLinks(userId: $userId) {
          id
          linkText
          url
          updated
        }
      }
    `,
    { variables: { userId: currentUser.id } }
  );

  //adds link as a favorite when built inside of the UserLinksPage
  // const overrideLinkDefaults = (link = {}) => {
  //   if (!link.id) {
  //     return link;
  //   }

  //   const favoriteLinks = Array.isArray(link.favoriteLinks)
  //     ? [...link.favoriteLinks, link.id]
  //     : [link.id];

  //   return {
  //     ...link,
  //     favoriteLinks,
  //   };
  // };

  if (error) return `Error in UserLinksPage query! ${error.message}`;

  return (
    <>
      {loading && <LinearProgress />}
      {!loading && !error && (
        <LinksPage
          links={data.getFavoriteLinks}
          // overrideLinkDefaults={overrideLinkDefaults}
          refetchQuery="GetFavoriteLinks"
          userFavoriteId={currentUser.id}
        />
      )}
    </>
  );
};

export default UserLinksPage;
