//Instructions to template this elsewhere:
//1. find / replace all "createGitHubSite" with "yourMutationsName"...should match backend name EXACTLY (necessary for data object)
//2. capitalize the first letter of the named mutation following the keyword "mutation" and following "use" (first line)
//3. Change the input variable names, i.e., "site" everywhere via find/replace
//4. Capitalize the replacement for "Site" in "newSite" in all locations (3 ish)
//5. Change the input variable type to match the backend, i.e., SiteInput
//6. Change the output data of the mutation to match backend / be what you want ("id" at a min usually)
//7. Add any desired refetch queries in the list (capital letter start)
//8. That's it!
import { gql, useMutation } from "@apollo/client";

export const useCreateGitHubSiteMutation = () => {
  const [
    createGitHubSiteMutation,
    {
      loading: createGitHubSiteIsLoading,
      error: createGitHubSiteHasError,
      data: createGitHubSiteData,
    },
  ] = useMutation(
    gql`
      mutation CreateGitHubSite($site: SiteInput!) {
        createGitHubSite(site: $site) {
          id
          subdomain
          parentDomain
          isCustomDomain
          admins {
            email
          }
          editors {
            email
          }
        }
      }
    `,
    {
      refetchQueries: [],
    }
  );

  // returns a promise
  const createGitHubSite = (site) => {
    //mutate functions return a promise that has a data object
    //this can be used to update the state
    //apparently onCompleted and onError can't be passed here,
    //even though the docs make it seem that they should.  Read
    //threads here: https://issueexplorer.com/issue/apollographql/apollo-client/8793
    //and https://github.com/apollographql/react-apollo/issues/3781
    return createGitHubSiteMutation({
      variables: {
        site,
      },
      //note that refetch queries is defined in the main mutation
    })
      .then(({ data: { createGitHubSite: newSite } }) => {
        if (newSite?.id) {
          return newSite;
        }
      })
      .catch((err) => console.log("Error in createGitHubSiteMutation: ", err));
  };

  const createGitHubSiteThunk = (site) => {
    return () => createGitHubSite(site);
  };

  // return the query, loading, error, and data objects for a stateful setup if desired
  return {
    createGitHubSiteMutation,
    createGitHubSiteIsLoading,
    createGitHubSiteHasError,
    createGitHubSiteData,
    createGitHubSite,
    createGitHubSiteThunk,
  };
};
