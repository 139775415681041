//React and Apollo gql dependencies
import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

//material UI dependencies
import { makeStyles } from "@material-ui/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

//other dependencies
import { v4 as uuidv4 } from "uuid";
import { LinearProgress } from "@material-ui/core";

const formWidth = "40%";
const formMinWidth = "270px";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "1rem",
  },
  textarea: {
    width: "100%",
    resize: "none",
    boxSizing: "border-box",
  },
  addIcon: {
    fontSize: 40,
    margin: "0 auto",
  },
  iconWrapper: {
    textAlign: "center",
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: theme.spacing(5, "auto"),
    minHeight: "3em",
    width: formWidth,
    minWidth: formMinWidth,
    borderRadius: "10px",
  },
  chip: {
    margin: theme.spacing(0.5),
    height: "2.5em",
  },
  buttonWrapper: {
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  button: {
    justifyContent: "center",
    display: "inline-block",
    margin: theme.spacing(1),
  },
  inputWrapper: {
    width: "100%",
    textAlign: "center",
  },
  input: {
    margin: theme.spacing(2),
    width: formWidth,
    minWidth: formMinWidth,
  },
  listWrapper: {
    width: "100%",
    textAlign: "center",
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    width: formWidth,
    minWidth: formMinWidth,
    margin: "auto",
    borderRadius: "10px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalWindow: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const CommunitiesPage = () => {
  const {
    loading: allCommunitiesQueryLoading,
    error: allCommunitiesQueryError,
    data: allCommunitiesQueryData,
  } = useQuery(
    gql`
      query GetAllCommunities {
        getAllCommunities {
          id
          name
        }
      }
    `
  );

  const existingCommunities = !(
    allCommunitiesQueryLoading || allCommunitiesQueryError
  )
    ? allCommunitiesQueryData.getAllCommunities
    : [];

  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [isNewCommunity, setIsNewCommunity] = useState(false);
  const [joinedCommunity, setJoinedCommunity] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCommunities, setSelectedCommunities] = useState([]);

  // const [existingCommunities, setExistingCommunities] = useState([
  //   {
  //     id: "0",
  //     name: "MAF",
  //     photoURL: null,
  //   },
  //   {
  //     id: "1",
  //     name: "CAF",
  //     photoURL: null,
  //   },
  //   {
  //     id: "2",
  //     name: "erob",
  //     photoURL: null,
  //   },
  // ]);

  const arrayContainsCommunity = (community, array) => {
    if (typeof community === "object") {
      return array.reduce(
        (isContained, nextCommunity) =>
          isContained || nextCommunity.id === community.id,
        false
      );
    }

    if (typeof community === "string") {
      return array.reduce(
        ([isContained, matchingCommunity], nextCommunity) => {
          if (isContained) {
            return [isContained, matchingCommunity];
          }

          if (nextCommunity.name.toUpperCase() === community.toUpperCase()) {
            return [true, nextCommunity];
          }

          return [false, null];
        },
        [false, null]
      );
    }
  };

  const selectCommunity = (community) => {
    return (event) => {
      const isSelected = arrayContainsCommunity(community, selectedCommunities);

      if (!isSelected) {
        setSelectedCommunities((currentSelectedCommunities) => [
          ...currentSelectedCommunities,
          community,
        ]);
      }
    };
  };

  const removeCommunity = (community) => {
    return (event) => {
      setSelectedCommunities((currentSelectedCommunities) =>
        currentSelectedCommunities.filter(
          (selectedCommunity) => selectedCommunity.id !== community.id
        )
      );
    };
  };

  const clearSelection = (event) => setSelectedCommunities([]);
  const updateFilter = (event) => setFilter(event.target.value);

  const evaluateInput = (event) => {
    if (event.charCode !== 13) {
      return;
    }

    const [communityExists, matchingCommunity] = arrayContainsCommunity(
      event.target.value,
      existingCommunities
    );

    if (communityExists) {
      selectCommunity(matchingCommunity)();
      setFilter("");
    } else {
      setFilter(event.target.value);
      setModalOpen(true);
    }
  };

  const [
    createCommunityMutation,
    {
      loading: createCommunityLoading,
      error: createCommunityError,
      data: createCommunityData,
    },
  ] = useMutation(
    gql`
      mutation CreateCommunity($name: String!) {
        createCommunity(name: $name) {
          id
          name
        }
      }
    `,
    {
      refetchQueries: ["GetAllCommunities"],
    }
  );

  const createCommunity = (name) => {
    return () => {
      //mutate functions return a promise that has a data object
      //this can be used to update the state / selected communities
      //apparently onCompleted and onError can't be passed here,
      //even though the docs make it seem that they should.  Read
      //threads here: https://issueexplorer.com/issue/apollographql/apollo-client/8793
      //and https://github.com/apollographql/react-apollo/issues/3781
      createCommunityMutation({
        variables: {
          name,
        },
        //note that refetch queries is defined in the main mutation
        //and handles updating the list of existing communities
      })
        .then(({ data: { createCommunity: newCommunity } }) => {
          if (newCommunity?.id) {
            selectCommunity(newCommunity)();
          }
        })
        .catch((err) => console.log("Error in createCommunityMutation"));

      setFilter("");
      setIsNewCommunity(true);
      handleModalClose();
    };
  };

  const [
    joinCommunityMutation,
    {
      loading: joinCommunityLoading,
      error: joinCommunityError,
      data: joinCommunityData,
    },
  ] = useMutation(
    gql`
      mutation JoinCommunity($communityId: ID!) {
        joinCommunity(communityId: $communityId) {
          id
          communities
        }
      }
    `,
    {
      refetchQueries: ["GetAllCommunities"],
    }
  );

  const joinSelectedCommunities = () => {
    selectedCommunities.forEach((community) =>
      joinCommunityMutation({
        variables: {
          communityId: community.id,
        },
      })
    );

    clearSelection();
    setJoinedCommunity(true);
  };

  const handleSnackbarClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsNewCommunity(false);
    setJoinedCommunity(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setFilter("");
  };

  if (allCommunitiesQueryError)
    return `Error loading all communities: ${allCommunitiesQueryError.message}`;

  return (
    <>
      {allCommunitiesQueryLoading && <LinearProgress />}
      {!allCommunitiesQueryLoading && (
        <>
          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={modalOpen}
              onClose={handleModalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={modalOpen}>
                <div className={classes.modalWindow}>
                  <p>
                    <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                      {filter}
                    </span>{" "}
                    is not currently a community. Would you like to create it?
                  </p>
                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.button}
                      variant="outlined"
                      color="default"
                      onClick={handleModalClose}
                    >
                      No
                    </Button>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      onClick={createCommunity(filter)}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              </Fade>
            </Modal>
          </div>
          <Snackbar
            open={isNewCommunity || joinedCommunity}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <MuiAlert
              onClose={handleSnackbarClose}
              elevation={6}
              variant="filled"
              severity="info"
            >
              {isNewCommunity && "New Community Created!"}
              {joinedCommunity && "Successfully Joined Communities!"}
            </MuiAlert>
          </Snackbar>
          <Typography
            style={{ padding: "1em 0 0.5em 0" }}
            align="center"
            color="inherit"
            variant="h4"
          >
            Create or Join a Community
          </Typography>
          <div className={classes.inputWrapper}>
            <TextField
              id="communityName"
              label="Community Name"
              value={filter}
              variant="outlined"
              className={classes.input}
              onChange={updateFilter}
              onKeyPress={evaluateInput}
            />
          </div>
          <div className={classes.listWrapper}>
            <List dense={false} className={classes.list}>
              {existingCommunities
                .filter((item) =>
                  item.name.toUpperCase().includes(filter.toUpperCase())
                )
                .map((community) => {
                  return (
                    <ListItem
                      key={community.id}
                      onClick={selectCommunity(community)}
                    >
                      <ListItemAvatar>
                        <Avatar alt={community.name} src={community.name} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={community.name}
                        secondary={false ? "Description here" : null}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </div>
          <Paper elevation={3} component="ul" className={classes.paper}>
            {selectedCommunities.map((community) => {
              return (
                <li key={community.id}>
                  <Chip
                    color="primary"
                    label={community.name}
                    onDelete={removeCommunity(community)}
                    avatar={
                      <Avatar alt={community.name} src={community.name} />
                    }
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Paper>
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              variant="outlined"
              color="default"
              onClick={clearSelection}
            >
              Clear
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={joinSelectedCommunities}
            >
              Submit
            </Button>
          </div>
          {/* <div className={classes.iconWrapper}>
            <IconButton onClick={addNewLink}>
              <AddCircleOutlineIcon className={classes.addIcon} />
            </IconButton>
          </div> */}
        </>
      )}
    </>
  );
};

export default CommunitiesPage;
