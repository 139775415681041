import React, { useState, useContext } from "react";
import { Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { red } from "@material-ui/core/colors";
import LaunchIcon from "@material-ui/icons/Launch";
import EditIcon from "@material-ui/icons/Edit";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

import { getRoute } from "../util/routes";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

//image type is one of the public image titles. If null, undefined, or mispelled, it tries to pull from the
//site url, and if that fails it defaults to a basic image
export const SiteCard = ({
  title = "Title",
  subheader = "subheader",
  description = "description",
  newSiteLaunch = false,
  customCtaText = "",
  customCtaIcon = null,
  demoUrl = null,
  srcImg = null,
  srcAlt = "Image from Linked Website",
  ctaAction = () => {},
}) => {
  const pageNotFoundLink = `${process.env.REACT_APP_SITES_BASE_URL}${getRoute(
    "notFound"
  )}`;

  const trimTrailingSlash = (url) => {
    return url.slice(-1) === "/" ? url.slice(0, url.length - 1) : url;
  };

  return (
    <>
      <Card
        style={{
          width: 260,
          display: "inline-block",
          margin: "2em 0px 0px 2em",
          textAlign: "left",
        }}
      >
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={title}
          subheader={subheader}
        />
        <CardMedia style={{ textAlign: "center" }}>
          <img
            src={srcImg}
            alt={srcAlt}
            height={150}
            width="auto"
            style={{ objectFit: "cover" }}
          />
        </CardMedia>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          {demoUrl && (
            <Tooltip
              title={
                newSiteLaunch ? "View Live Template Demo" : "View Live Site"
              }
            >
              <a
                href={demoUrl || pageNotFoundLink}
                style={{ textDecoration: "none", color: "inherit" }}
                target="_blank"
              >
                <IconButton
                  aria-label={
                    newSiteLaunch ? "View Live Template Demo" : "View Live Site"
                  }
                >
                  <LaunchIcon />
                </IconButton>
              </a>
            </Tooltip>
          )}
          {!newSiteLaunch && !customCtaText && (
            <Tooltip title="Edit Site Content">
              <a
                href={
                  demoUrl
                    ? `${trimTrailingSlash(demoUrl)}/admin`
                    : pageNotFoundLink
                }
                style={{ textDecoration: "none", color: "inherit" }}
                target="_blank"
              >
                <IconButton aria-label="edit site content">
                  <EditIcon />
                </IconButton>
              </a>
            </Tooltip>
          )}
          <Button
            style={{
              fontWeight: "bold",
              marginLeft: "auto",
              marginRight: customCtaText ? "auto" : 5,
            }}
            color="primary"
            variant="contained"
            startIcon={
              customCtaIcon
                ? customCtaIcon
                : !newSiteLaunch && (
                    <VerifiedUserIcon style={{ fontSize: 20 }} />
                  )
            }
            onClick={ctaAction}
          >
            {newSiteLaunch
              ? "Use This Template"
              : customCtaText
              ? customCtaText
              : "Admin"}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
