const futureRoutes = {
  path: "/",
  subPaths: {
    newSite: {
      path: "new-site/",
      subpaths: {},
    },
  },
};

const tempRoutes = {
  sites_admin: "/sites/admin/",
  sites: "/sites/",
  newSite: "/new-site/",
  notFound: "/not-found/",
  sightWords: "/silly-sight-words/",
  sitesAppAdminPanel: "/admin/",
};

export const getRoute = (key) => {
  return tempRoutes[key];
};
