// in Header.js
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";

import RightDrawerMenu from "./RightDrawerMenu";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "0 auto",
  },
}));

export const Header = ({ currentUser }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  // const toggleDrawer = (event) => toggleDrawerOpen((currentState) => !currentState);
  // const toggleDrawer = (event) => setDrawerOpen(true);

  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        {currentUser && (
          <Avatar alt={currentUser.username} src={currentUser.username} />
        )}
        <Typography
          className={classes.title}
          component="span"
          type="title"
          color="inherit"
        >
          Home {currentUser ? currentUser.username : ""}
        </Typography>
        <IconButton onClick={toggleDrawer(!drawerOpen)}>
          <MenuIcon />
        </IconButton>
        <RightDrawerMenu
          isOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          currentUser={currentUser}
        />
      </Toolbar>
    </AppBar>
  );
};
