//react imports
import React from "react";

//other imports
import { Switch, Route, Redirect } from "react-router-dom";

//local imports
import AllLinksPage from "../components/AllLinksPage";
import UserLinksPage from "../components/UserLinksPage";
import CommunitiesPage from "../components/CommunitiesPage";
import CommunityLinksPage from "../components/CommunityLinksPage";
import { PageNotFound } from "../components/PageNotFound";

export const LinksRouter = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/all" />
      </Route>
      <Route path="/all">
        <AllLinksPage />
      </Route>
      <Route path="/favorites">
        <UserLinksPage />
      </Route>
      <Route path="/communities/:communityId/links">
        <CommunityLinksPage />
      </Route>
      <Route path="/communities">
        <CommunitiesPage />
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};
