//react imports
import React from "react";

//other imports
import { Switch, Route, Redirect } from "react-router-dom";

//local imports
import { SightWordsScreen } from "../screens/SightWordsScreen";
import { PageNotFound } from "../components/PageNotFound";
import { getRoute } from "../util/routes";
import { EducationScreen } from "../screens/EducationScreen";

export const EducationRouter = () => {
  return (
    <Switch>
      <Route exact path="/">
        <EducationScreen />
      </Route>
      <Route path={getRoute("sightWords")}>
        <SightWordsScreen />
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};
