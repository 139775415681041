import React from "react";
import "./typing.css";

// follow tutorial here: https://dev.to/3mustard/create-a-typing-animation-in-react-17o0
// plus flex for vertical center
export const Typing = () => (
  <div className="typing">
    <div>
      <div className="typing-dot"></div>
      <div className="typing-dot"></div>
      <div className="typing-dot"></div>
    </div>
  </div>
);
