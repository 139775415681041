import { gql, useLazyQuery } from "@apollo/client";

export const useGetAllSitesLazyQuery = () => {
  const [
    getAllSitesLazyQuery,
    {
      loading: getAllSitesIsLoading,
      error: getAllSitesHasError,
      data: getAllSitesData,
    },
  ] = useLazyQuery(
    gql`
      query GetAllSites {
        getAllSites {
          id
          subdomain
          parentDomain
          primaryBaseURL
          isCustomDomain
          # siteTemplateID
          # admins {
          #   email
          #   lastLoggedIn
          # }
          # editors {
          #   email
          #   lastLoggedIn
          # }
          metadata {
            pageStatus
            pageLastLive
            createdAt
            httpsLastLive
            customDomainLastLive
            customDomainHttpsLastLive
            ogTitle
            ogDescription
            ogImage
          }
        }
      }
    `
  );

  // returns a promise
  const getAllSites = () => {
    return getAllSitesLazyQuery()
      .then(({ data: { getAllSites: newSites } }) => {
        if (Array.isArray(newSites)) {
          return newSites;
        }
      })
      .catch((err) => console.log("Error in getAllSitesLazyQuery: ", err));
  };

  const getAllSitesThunk = () => {
    return () => getAllSites();
  };

  // return the query, loading, error, and data objects for a stateful setup if desired
  return {
    getAllSitesLazyQuery,
    getAllSitesIsLoading,
    getAllSitesHasError,
    getAllSitesData,
    getAllSites,
    getAllSitesThunk,
  };
};
