import React, { createContext, useState, useEffect } from "react";
import { firebaseInstance } from "../../src/util/firebase";

import {
  useAddOrUpdateUserFromFirebaseMutation,
  useGetCurrentUserQuery,
} from "../../src/util/api/User";

// null is the default if no user is signed in through firebase,
// so we need to differentiate between whether firebase is still trying to
// return an initial call, or whether it returned null (hence use undefined)
export const UserContext = createContext({ currentUser: undefined });
export const UserProvider = ({ children }) => {
  // null is the default if no user is signed in through firebase,
  // so we need to differentiate between whether firebase is still trying to
  // return an initial call, or whether it returned null (hence use undefined)

  const [state, setState] = useState({ currentUser: undefined });
  const updateUser = useAddOrUpdateUserFromFirebaseMutation((newUser) =>
    setState({ currentUser: newUser })
  );
  useGetCurrentUserQuery((newUser) => setState({ currentUser: newUser }));

  useEffect(() => {
    const unregisterAuthObserver = firebaseInstance
      .auth()
      .onAuthStateChanged((authenticatedUser) => {
        if (authenticatedUser) {
          const firebaseUserInput = {
            id: authenticatedUser.uid,
            email: authenticatedUser.email,
            photoURL: authenticatedUser.photoURL,
            username: authenticatedUser.displayName,
          };
          updateUser({ variables: { firebaseUser: firebaseUserInput } });
        } else {
          setState({ currentUser: null });
        }
      });

    return () => unregisterAuthObserver();
  }, []);

  return (
    <UserContext.Provider value={[state.currentUser, setState]}>
      {children}
    </UserContext.Provider>
  );
};
