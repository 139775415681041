//React and Apollo gql dependencies
import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";

//material UI dependencies
import { makeStyles } from "@material-ui/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";

//other dependencies
import { v4 as uuidv4 } from "uuid";

//local project dependencies
import Link from "./Link";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "1rem",
  },
  button: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  textarea: {
    width: "100%",
    resize: "none",
    boxSizing: "border-box",
  },
  addIcon: {
    fontSize: 40,
    margin: "0 auto",
  },
  iconWrapper: {
    textAlign: "center",
  },
}));

const LinksPage = ({
  links = [],
  overrideLinkDefaults,
  refetchQuery = "GetAllLinks",
  userFavoriteId = null,
  communityLinkId = null,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    newLinks: [],
  });

  const { newLinks } = state;

  const addNewLink = (event) => {
    setState((currentState) => {
      const nextState = { ...currentState };

      let newLink = {
        id: uuidv4(),
        linkText: "",
        description: "",
        url: "",
        updated: new Date(),
      };

      if (overrideLinkDefaults) {
        newLink = overrideLinkDefaults(newLink);
      }

      nextState.newLinks.push(newLink);

      return nextState;
    });
  };

  const [deleteLink] = useMutation(
    gql`
      mutation DeleteLink($id: ID!) {
        deleteLink(id: $id) {
          id
        }
      }
    `,
    {
      refetchQueries: [refetchQuery],
    }
  );

  const deleteExistingLinkThunk = (linkId) => {
    return () => {
      deleteLink({
        variables: {
          id: linkId,
        },
      });
    };
  };

  const deleteNewLinkThunk = (linkId) => {
    return () => {
      setState((currentState) => {
        return {
          ...currentState,
          newLinks: currentState.newLinks.filter((link) => link.id !== linkId),
        };
      });
    };
  };

  return (
    <>
      {links.map((link) => (
        <Link
          key={link.id}
          link={link}
          refetchQuery={refetchQuery}
          deleteCallbackThunk={deleteExistingLinkThunk}
          userFavoriteId={userFavoriteId}
          communityLinkId={communityLinkId}
        />
      ))}
      {newLinks.map((link) => (
        <Link
          key={link.id}
          link={link}
          isNew={true}
          refetchQuery={refetchQuery}
          deleteCallbackThunk={deleteNewLinkThunk}
          userFavoriteId={userFavoriteId}
          communityLinkId={communityLinkId}
        />
      ))}
      <div className={classes.iconWrapper}>
        <IconButton onClick={addNewLink}>
          <AddCircleOutlineIcon className={classes.addIcon} />
        </IconButton>
      </div>
    </>
  );
};

export default LinksPage;
