//React and Apollo gql dependencies
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

//mui import
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

//local imports
import { ScreenTemplate } from "../components/ScreenTemplate";
import { SiteCard } from "../components/SiteCard";
import SimpleCleanDraw from "../assets/img/simple-clean-draw.svg";

// util imports
import { useGetAdminSitesForCurrentUserLazyQuery } from "../util/api/Site/getAdminSitesForCurrentUser";
import { useUpdateSitesLaunchStatusMutation } from "../util/api/Site/updateSitesLaunchStatus";
import { getRoute } from "../util/routes";

const useStyles = makeStyles((theme) => {
  return {};
});

export const SitesScreen = () => {
  const classes = useStyles();

  const {
    getAdminSitesForCurrentUserLazyQuery,
    getAdminSitesForCurrentUserIsLoading,
    getAdminSitesForCurrentUserHasError,
    getAdminSitesForCurrentUserData,
  } = useGetAdminSitesForCurrentUserLazyQuery();

  const { updateSitesLaunchStatus, updateSitesLaunchStatusIsLoading } =
    useUpdateSitesLaunchStatusMutation();

  const sites =
    getAdminSitesForCurrentUserData?.getAdminSitesForCurrentUser || [];

  // will autopopulate the data objects, no need to put into state
  // also will allow data to be updated by refetch queries
  useEffect(() => {
    getAdminSitesForCurrentUserLazyQuery();
    updateSitesLaunchStatus(
      sites.map((site) => ({
        id: site.id,
      }))
    );
  }, []);

  return (
    <>
      {getAdminSitesForCurrentUserIsLoading && <LinearProgress />}
      {!getAdminSitesForCurrentUserIsLoading && (
        <ScreenTemplate title="Sites">
          <div style={{ textAlign: "center" }}>
            {sites.length > 0 ? (
              sites.map((site) => {
                const osdlURL = `https://osdl.io/${site.subdomain}`;

                return (
                  <SiteCard
                    key={site.id}
                    title={site.metadata?.ogTitle || osdlURL}
                    subheader=""
                    description={site.metadata?.ogDescription || ""}
                    demoUrl={osdlURL}
                    srcImg={
                      `https://osdl.io${site.metadata?.ogImage}` ||
                      SimpleCleanDraw
                    }
                    srcAlt={site.metadata?.ogTitle || osdlURL}
                    ctaAction={() =>
                      (window.location.href = getRoute("sites_admin"))
                    }
                  />
                );
              })
            ) : (
              <Typography
                style={{ padding: "1em 0 0.5em 0" }}
                align="center"
                color="inherit"
                variant="h6"
              >
                Get started by clicking the New Site Button Now!
              </Typography>
            )}
            {/* <SiteCard
              title="Daily Business"
              subheader="Beautifully crafted."
              description="A clean, responsive template for a professional business website."
              demoUrl="https://general-template.opensites.io/"
              srcImg={BusinessBasicsDraw}
              srcAlt="Website Drawing"
              ctaAction={openModal("935a2756-4808-11ed-a388-672eb9344e2d")}
            /> */}
          </div>
        </ScreenTemplate>
      )}
    </>
  );
};
