//Instructions to template this elsewhere:
//1. find / replace all "buildAutoChat" with "yourQueryName"...should match backend name EXACTLY (necessary for data object)
//2. capitalize the first letter of the named query following the keyword "query" and following "use" (first line)
//3. Change the input variable names, i.e., "prompt" everywhere via find/replace
//4. Capitalize the replacement for "Prompt" in "newPrompt" in all locations (3 ish)
//5. Change the input variable type to match the backend, i.e., String
//6. Change the output data of the query to match backend / be what you want ("id" at a min usually)
//7. Add any desired refetch queries in the list (capital letter start)
//8. That's it!
import { gql, useLazyQuery } from "@apollo/client";

export const useBuildAutoChatLazyQuery = () => {
  const [
    buildAutoChatLazyQuery,
    {
      loading: buildAutoChatIsLoading,
      error: buildAutoChatHasError,
      data: buildAutoChatData,
    },
  ] = useLazyQuery(
    gql`
      query BuildAutoChat($prompt: String) {
        buildAutoChat(prompt: $prompt) {
          body
        }
      }
    `
  );

  // returns a promise
  const buildAutoChat = (prompt) => {
    //lazy query functions return a promise that has a data object
    //this can be used to update the state
    //apparently onCompleted and onError can't be passed here,
    //even though the docs make it seem that they should.  Read
    //threads here: https://issueexplorer.com/issue/apollographql/apollo-client/8793
    //and https://github.com/apollographql/react-apollo/issues/3781
    return buildAutoChatLazyQuery({
      variables: {
        prompt,
      },
    })
      .then(({ data: { buildAutoChat: newPrompt } }) => {
        if (newPrompt?.body) {
          return newPrompt.body;
        }
      })
      .catch((err) => console.log("Error in buildAutoChatLazyQuery: ", err));
  };

  const buildAutoChatThunk = (prompt) => {
    return () => buildAutoChat(prompt);
  };

  // return the query, loading, error, and data objects for a stateful setup if desired
  return {
    buildAutoChatLazyQuery,
    buildAutoChatIsLoading,
    buildAutoChatHasError,
    buildAutoChatData,
    buildAutoChat,
    buildAutoChatThunk,
  };
};
