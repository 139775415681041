//React and Apollo gql dependencies
import React from "react";
import { Link } from "react-router-dom";
import { Typography, Divider, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LandscapeIcon from "@material-ui/icons/Landscape";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

//local imports
import { getRoute } from "../util/routes";

const useStyles = makeStyles((theme) => {
  const maxBoxWidth = 1050;
  return {
    container: {
      width: maxBoxWidth,
      maxWidth: "100%",
      paddingRight: "5%",
      margin: "0 auto",
    },
    main: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "auto",
      boxSizing: "border-box",
      overflow: "hidden",
      overflowWrap: "break-word",
    },
    verticalCenterFlex: {
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
    },
    titleIcon: {
      color: theme.palette.secondary,
      fontSize: 40,
    },
    mainText: {
      paddingTop: 10,
      paddingLeft: 10,
      paddingBottom: 10,
      display: "inline-block",
    },
  };
});

export const ScreenTemplate = ({
  children,
  title = "Page Title",
  subtitle = null,
  newSiteCta = true,
  smallMode = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {!smallMode && (
        <div className={`${classes.main} ${classes.verticalCenterFlex}`}>
          <LandscapeIcon fontSize="large" className={classes.titleIcon} />
          <div className={classes.mainText}>
            <Typography
              align="start"
              color="inherit"
              variant="h4"
              component="div"
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                align="start"
                color="inherit"
                variant="subtitle2"
                component="div"
              >
                {subtitle}
              </Typography>
            )}
          </div>
        </div>
      )}
      <Divider variant="middle" />
      <div className={classes.main} style={{ paddingTop: "2em" }}>
        {newSiteCta && (
          <Link
            to={getRoute("newSite")}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              style={{ fontWeight: "bold" }}
              color="primary"
              variant="contained"
              startIcon={
                <AddRoundedIcon style={{ fontWeight: "bold", fontSize: 24 }} />
              }
            >
              New Site
            </Button>
          </Link>
        )}
        {children}
      </div>
    </div>
  );
};
