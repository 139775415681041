import React, { useState, useContext } from "react";
import { Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { red } from "@material-ui/core/colors";
import SendIcon from "@material-ui/icons/Send";
import EditIcon from "@material-ui/icons/Edit";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

import { getRoute } from "../../util/routes";
import { sightWords, extraWords } from "../../data/sightWords";
import { useBuildAutoChatLazyQuery } from "../../util/api/Message/buildAutoChat";
import { Typing } from "./Typing/Typing";

import theme from "../../util/theme";
import { useEffect } from "react";

//image type is one of the public image titles. If null, undefined, or mispelled, it tries to pull from the
//site url, and if that fails it defaults to a basic image
export const SightWordPhone = ({
  initialState = [],
  contactName = "Cool Robot",
  draftMessage = "",
  isShort = false,
  onSendCallback = () => {},
  onAddCallback = (newMessages) => {},
}) => {
  const [messages, setMessages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);

  const { buildAutoChat } = useBuildAutoChatLazyQuery();

  useEffect(() => {
    setMessages(initialState);
  }, []);

  const addMessage = (isMine, content) => {
    setMessages((oldMessages) => {
      const newMessages = [...oldMessages, { isMine, content }];
      onAddCallback(newMessages);
      return newMessages;
    });
  };

  const getResponse = (prompt) => {
    setIsGenerating(true);

    setTimeout(() => {
      buildAutoChat(prompt).then((responseText) => {
        addMessage(false, responseText);
        setIsGenerating(false);
        console.log(responseText);
      });
    }, 2000);
  };

  const sendMessage = (content) => () => {
    addMessage(true, content);
    getResponse(content);
    onSendCallback();
  };

  return (
    <>
      <Card
        style={{
          width: 300,
          height: 500,
          maxHeight: "80vh",
          display: "inline-block",
          textAlign: "left",
        }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              R
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={contactName}
          subheader={contactName}
        />
        <CardContent
          style={{ display: "flex", flexDirection: "column", height: "85%" }}
        >
          <div
            style={{
              height: "80%",
              overflow: "auto",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <div>
              {messages.map(({ isMine, content }, index) => (
                <div
                  style={{
                    borderStyle: "solid",
                    borderRadius: 20,
                    borderWidth: 2,
                    padding: 7,
                    marginTop: 2,
                    marginLeft: isMine ? "auto" : 5,
                    marginRight: isMine ? 5 : "auto",
                    textAlign: "left",
                    width: "max-content",
                    maxWidth: "90%",
                    backgroundColor: isMine ? "blue" : "white",
                    borderColor: isMine ? "blue" : "black",
                  }}
                  key={`${index}-${content}`}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      fontSize: 15,
                      color: isMine ? "white" : "black",
                    }}
                  >
                    {content}
                  </Typography>
                </div>
              ))}
              {isGenerating && <Typing />}
            </div>
          </div>
          <div
            style={{
              flexGrow: 0,
              borderStyle: "solid",
              borderRadius: 20,
              borderWidth: 2,
              padding: 7,
              margin: "auto",
              marginTop: 20,
              width: "80%",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: 5,
                flexGrow: 1,
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                style={{
                  fontSize: 15,
                  color: draftMessage ? "black" : "gray",
                }}
              >
                {draftMessage.toUpperCase() || "Click words to write"}
              </Typography>
            </div>
            <Tooltip
              style={{
                marginLeft: "auto",
                marginRight: 5,
                marginLeft: 5,
                padding: 2,
              }}
              title="Send message to robot"
            >
              <IconButton
                aria-label="Send message to robot"
                onClick={sendMessage(draftMessage)}
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          </div>
          {isShort && <div style={{ margin: 10 }} />}
        </CardContent>
      </Card>
    </>
  );
};
