//React and Apollo gql dependencies
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

//mui
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

//local imports
import { ScreenTemplate } from "../components/ScreenTemplate";
import { SiteCard } from "../components/SiteCard";
import { getRoute } from "../util/routes";

//images for cards
import SightWordsDraw from "../assets/img/sight-words-draw.svg";

//defaults
import { defaultSiteTemplateID } from "../util/defaults";

const useStyles = makeStyles((theme) => {
  return {};
});

const ctaAction = () => {
  return true;
};

export const EducationScreen = () => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [siteTemplateID, setSiteTemplateID] = useState(defaultSiteTemplateID);

  const openModal =
    (newSiteTemplateID = defaultSiteTemplateID) =>
    () => {
      setModalOpen(true);
      setSiteTemplateID(newSiteTemplateID);
    };

  return (
    <>
      <ScreenTemplate
        title="Educational Games Using AI"
        subtitle="Launch a mini-game below!"
        newSiteCta={false}
      >
        <div style={{ textAlign: "center" }}>
          <SiteCard
            title="Silly Sight Words"
            subheader="Learn your sight words through a chat with an AI robot!"
            description=""
            customCtaText="Play now!"
            customCtaIcon={<PlayArrowIcon style={{ fontSize: 20 }} />}
            srcImg={SightWordsDraw}
            srcAlt="Reading a book for sight words"
            ctaAction={() => (window.location.href = getRoute("sightWords"))}
          />
        </div>
      </ScreenTemplate>
    </>
  );
};
