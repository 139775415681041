//React and Apollo gql dependencies
import React from "react";
import { gql, useQuery } from "@apollo/client";

//material UI dependencies
import LinearProgress from "@material-ui/core/LinearProgress";

//local project dependencies
import LinksPage from "../../src/components/LinksPage";

const AllLinksPage = () => {
  const { loading, error, data } = useQuery(
    gql`
      query GetAllLinks {
        getAllLinks {
          id
          linkText
          url
          updated
        }
      }
    `
  );

  if (error) return `Error! ${error.message}`;

  return (
    <>
      {loading && <LinearProgress />}
      {!loading && !error && (
        <LinksPage links={data.getAllLinks} refetchQuery="GetAllLinks" />
      )}
    </>
  );
};

export default AllLinksPage;
