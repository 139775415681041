import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import UpdateIcon from "@material-ui/icons/Update";
import WarningIcon from "@material-ui/icons/Warning";
import Link from "@material-ui/core/Link";
import LocalParkingIcon from "@material-ui/icons/LocalParking";

const StatusIcon = ({ status = "unknown" }) => {
  return (
    <>
      {status === "up" && <VerifiedUserIcon style={{ color: "green" }} />}
      {status === "down" && <WarningIcon style={{ color: "#d32f2f" }} />}
      {status === "unknown" && <UpdateIcon style={{ color: "#ff9800" }} />}
      {status === "parking" && (
        <LocalParkingIcon style={{ color: "#ff9800" }} />
      )}
      {status === "na" && (
        <Typography style={{ color: "#9e9e9e" }}>N/A</Typography>
      )}
    </>
  );
};

export const AdminRow = ({
  row = {},
  isItemSelected = false,
  labelId = "",
  onClick = () => {},
}) => {
  const { id, parentDomain, subdomain, isCustomDomain, metadata } = row || {};
  const {
    pageStatus,
    pageLastLive,
    createdAt,
    httpsLastLive,
    customDomainLastLive,
    customDomainHttpsLastLive,
  } = metadata || {};

  // row overiew calculations
  const osdlURL = (
    <Link
      href={`https://osdl.io/${subdomain}`}
      target="_blank"
      rel="noopener"
      underline="hover"
    >
      {`osdl.io/${subdomain}`}
    </Link>
  );

  //one of empty, parking-live, page-live, error.
  //TODO compare time refreshed
  let pageStatusIconState = "down";
  if (pageStatus === "page-live") {
    pageStatusIconState = "up";
  } else if (pageStatus === "parking-live") {
    pageStatusIconState = "parking";
  }

  let customDomainStatusIconState = "na";
  if (isCustomDomain) {
    if (customDomainLastLive) {
      customDomainStatusIconState = "up";
    } else {
      customDomainStatusIconState = "down";
    }
  }

  const httpsStatusIconState =
    httpsLastLive && (!isCustomDomain || customDomainHttpsLastLive)
      ? "up"
      : "down";

  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        hover
        onClick={onClick}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(event) => {
              setOpen((lastOpen) => !lastOpen);
              event.stopPropagation();
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {osdlURL}
        </TableCell>
        <TableCell align="left">
          <StatusIcon status={pageStatusIconState} />
        </TableCell>
        <TableCell align="left">
          <StatusIcon status={customDomainStatusIconState} />
        </TableCell>
        <TableCell align="left">
          <StatusIcon status={httpsStatusIconState} />
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Site Details
              </Typography>
              {/* <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
