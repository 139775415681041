//React and Apollo gql dependencies
import React from "react";
import { makeStyles } from "@material-ui/styles";

//local imports
import { ScreenTemplate } from "../components/ScreenTemplate";
import { SitesAppAdminTable } from "../components/SightsAppAdminTable/SitesAppAdminTable";

const useStyles = makeStyles((theme) => {
  return {};
});

export const SitesAppAdminScreen = ({ currentUserOnly = true }) => {
  const classes = useStyles();

  return (
    <ScreenTemplate title="Site App Admin Panel">
      <div style={{ textAlign: "center" }}>
        <SitesAppAdminTable currentUserOnly={currentUserOnly} />
      </div>
    </ScreenTemplate>
  );
};
