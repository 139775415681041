import { gql, useLazyQuery } from "@apollo/client";

export const useGetAdminSitesForCurrentUserLazyQuery = () => {
  const [
    getAdminSitesForCurrentUserLazyQuery,
    {
      loading: getAdminSitesForCurrentUserIsLoading,
      error: getAdminSitesForCurrentUserHasError,
      data: getAdminSitesForCurrentUserData,
    },
  ] = useLazyQuery(
    gql`
      query GetAdminSitesForCurrentUser {
        getAdminSitesForCurrentUser {
          id
          subdomain
          parentDomain
          primaryBaseURL
          isCustomDomain
          # siteTemplateID
          # admins {
          #   email
          #   lastLoggedIn
          # }
          # editors {
          #   email
          #   lastLoggedIn
          # }
          metadata {
            pageStatus
            pageLastLive
            createdAt
            httpsLastLive
            customDomainLastLive
            customDomainHttpsLastLive
            ogTitle
            ogDescription
            ogImage
          }
        }
      }
    `
  );

  // returns a promise
  const getAdminSitesForCurrentUser = () => {
    return getAdminSitesForCurrentUserLazyQuery()
      .then(({ data: { getAdminSitesForCurrentUser: newSites } }) => {
        if (Array.isArray(newSites)) {
          return newSites;
        }
      })
      .catch((err) =>
        console.log("Error in getAdminSitesForCurrentUserLazyQuery: ", err)
      );
  };

  const getAdminSitesForCurrentUserThunk = () => {
    return () => getAdminSitesForCurrentUser();
  };

  // return the query, loading, error, and data objects for a stateful setup if desired
  return {
    getAdminSitesForCurrentUserLazyQuery,
    getAdminSitesForCurrentUserIsLoading,
    getAdminSitesForCurrentUserHasError,
    getAdminSitesForCurrentUserData,
    getAdminSitesForCurrentUser,
    getAdminSitesForCurrentUserThunk,
  };
};
