import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PublicIcon from "@material-ui/icons/Public";
import MailIcon from "@material-ui/icons/Mail";
import CancelIcon from "@material-ui/icons/Cancel";
import FavoriteIcon from "@material-ui/icons/Favorite";
import BusinessIcon from "@material-ui/icons/Business";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import GroupIcon from "@material-ui/icons/Group";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import LandscapeIcon from "@material-ui/icons/Landscape";
import LinkIcon from "@material-ui/icons/Link";
import SchoolIcon from "@material-ui/icons/School";

import { firebaseInstance } from "../../src/util/firebase";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const RightDrawerMenu = ({ isOpen = false, toggleDrawer, currentUser }) => {
  const {
    loading: userCommunitiesLoading,
    error: userCommunitiesError,
    data: userCommunitiesData,
  } = useQuery(
    gql`
      query GetCommunities($ids: [ID]!) {
        getCommunities(ids: $ids) {
          id
          name
          photoURL
        }
      }
    `,
    {
      variables: {
        ids: currentUser.communities,
      },
    }
  );

  const classes = useStyles();

  const DrawerSectionTitle = ({ title, bottomPadding = false }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          paddingLeft: 16,
          paddingTop: 16,
          paddingBottom: bottomPadding ? 16 : 0,
        }}
      >
        <Typography
          variant="body2"
          style={{ color: "gray", fontWeight: "bold" }}
        >
          {title}
        </Typography>
      </div>
    );
  };

  const renderListItem = ({ text, icon, onClick, path }) => {
    const listItem = (
      <ListItem button key={text} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );

    return path ? (
      <Link
        key={`link-${text}`}
        to={path}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        {listItem}
      </Link>
    ) : (
      listItem
    );
  };

  const list = () => (
    <div
      //   className={clsx(classes.list, {
      //     [classes.fullList]: anchor === "top" || anchor === "bottom",
      //   })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {[
          {
            text: "All Links",
            icon: <PublicIcon />,
            path: "/all",
          },
          {
            text: "My Favorite Links",
            icon: <FavoriteIcon />,
            path: "/favorites",
          },
          {
            text: "My Organization's Links",
            icon: <BusinessIcon />,
            onClick: () => console.log("Success!"),
          },
        ].map((option) => renderListItem(option))}
      </List>
      <Divider />
      {userCommunitiesError && (
        <DrawerSectionTitle
          title="My Communities (error loading)"
          bottomPadding
        />
      )}
      {!userCommunitiesError && userCommunitiesLoading && (
        <DrawerSectionTitle title="My Communities (loading...)" bottomPadding />
      )}
      {!userCommunitiesError &&
      !userCommunitiesLoading &&
      currentUser.communities.length > 0 ? (
        <>
          <DrawerSectionTitle title="My Communities" />
          <List>
            {userCommunitiesData.getCommunities.map(({ id, name, photoURL }) =>
              renderListItem({
                text: name,
                icon: <CheckBoxOutlineBlankIcon />,
                path: `/communities/${id}/links`,
              })
            )}
          </List>
        </>
      ) : (
        <DrawerSectionTitle
          title="My Communities (none yet...join below!)"
          bottomPadding
        />
      )}
      <Divider />
      <DrawerSectionTitle title="Opensites Apps" />
      <List>
        {[
          {
            text: "Flagship Sites",
            icon: <LandscapeIcon />,
            onClick: () =>
              (window.location.href = process.env.REACT_APP_SITES_BASE_URL),
          },
          {
            text: "Community Links",
            icon: <LinkIcon />,
            onClick: () =>
              (window.location.href = process.env.REACT_APP_LINKS_BASE_URL),
          },
          {
            text: "Educational AI Games",
            icon: <SchoolIcon />,
            onClick: () =>
              (window.location.href = process.env.REACT_APP_EDUCATION_BASE_URL),
          },
        ].map((item) => renderListItem(item))}
      </List>
      <Divider />
      <DrawerSectionTitle title="Other" />
      <List>
        {[
          {
            text: "Create or Join Community",
            icon: <GroupIcon />,
            path: "/communities",
          },
          {
            text: "My Profile",
            icon: <AccountCircleIcon />,
            onClick: () => console.log("Success!"),
          },
          {
            text: "Logout",
            icon: <CancelIcon />,
            onClick: () => firebaseInstance.auth().signOut(),
          },
        ].map((item) => renderListItem(item))}
      </List>
    </div>
  );

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
};

export default RightDrawerMenu;
