import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Chip from "@material-ui/core/Chip";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import { useCreateGitHubSiteMutation } from "../util/api/Site/createGitHubSite";
import { defaultSiteTemplateID } from "../util/defaults";

const steps = [
  "Pick your osdl.io site location (e.g., https://osdl.io/example)",
  "Custom Domain (optional)",
  "Invite editors via email",
  "Deploy your site!",
];

//callback should accept the subdomain
export const NewSiteStepper = ({
  siteTemplateID = defaultSiteTemplateID,
  callback = () => {},
}) => {
  //use this to dynamically resize text input component
  const [numLettersToExpand, setNumLettersToExpand] = useState(0);
  const classes = useStyles(numLettersToExpand);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [subdomain, setSubdomain] = useState(null);

  const [isCustomDomain, setIsCustomDomain] = useState(false);
  const [customDomainName, setCustomDomainName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const [nextEditor, setNextEditor] = useState("");
  const [editors, setEditors] = useState([]);

  const { createGitHubSite } = useCreateGitHubSiteMutation();

  const isStepOptional = (step) => {
    // return step === 1;
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    removeSkippedStep(activeStep);

    //use this rather than index as it is more readable and
    //robust to changing step order
    //workflow: press next, this opens the dialog, user sees alert, then accepts and fires handleNext
    //again if the domain is valid, at which point this conditional is not hit
    if (
      !dialogOpen && isCustomDomain &&
      steps[activeStep].toLowerCase().includes("custom domain")
    ) {
      setDialogOpen(true);
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    //save the data from this step
  };

  const handleFinish = () => {
    removeSkippedStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const primaryBaseURL = isCustomDomain
      ? `https://${customDomainName}`
      : null;

    //create the site
    createGitHubSite({
      subdomain,
      primaryBaseURL,
      isCustomDomain,
      editors,
      siteTemplateID,
    });

    //execute the callback passed to this component
    callback(subdomain);
  };

  const removeSkippedStep = (step) => {
    let newSkipped = skipped;
    if (isStepSkipped(step)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(step);
    }
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const isValidSubdomain = (newSubdomain) => {
    return isValidDomain(`${newSubdomain}.osdl.io`);
  };

  const isValidDomain = (newDomain) => {
    const domainPattern =
      /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;

    return domainPattern.test(newDomain);
  };

  const updateAndValidateSubdomain = (event) => {
    const newSubdomain = event.target.value;
    const maxResizeStringLength = 10;

    //dynamically resize text input
    setNumLettersToExpand(
      Math.max(0, newSubdomain.length - maxResizeStringLength)
    );

    //validate and show error if wrong

    setSubdomain(event.target.value);
  };

  const updateNextEditor = (event) => {
    const newEditor = event.target.value;
    const maxResizeStringLength = 10;

    //dynamically resize text input
    setNumLettersToExpand(
      Math.max(0, newEditor.length - maxResizeStringLength)
    );

    //remove these characters
    setNextEditor(event.target.value.replace(/[:;,/\\ ]/g, ""));
  };

  const validateAndAddEditor = (event) => {
    if (!isNextEditorKey(event)) {
      return;
    }

    //validate valid email address and show error if wrong

    //add editor.  move to the end of the list for users to see
    //if already added.
    setEditors((currentEditors) => [
      ...currentEditors.filter((email) => email !== nextEditor),
      nextEditor,
    ]);

    //clear the entry form
    setNextEditor("");
  };

  const isNextEditorKey = (event) => {
    //fire on enter (13), spacebar (32), colon (58), semi-colon (59),
    //comma (44), forward slash (47), back slash (92) presses
    return (
      event.charCode === 13 ||
      event.charCode === 32 ||
      event.charCode === 58 ||
      event.charCode === 59 ||
      event.charCode === 44 ||
      event.charCode === 47 ||
      event.charCode === 92
    );
  };

  const removeEditor = (email) => {
    return () => {
      setEditors((currentEditors) =>
        currentEditors.filter((editorEmail) => editorEmail !== email)
      );
    };
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === 0 && (
            <div className={`${classes.infoContainer}`}>
              <div
                className={`${classes.blockCenterFlex} ${classes.blockCenterFlexDynamicStepOne}`}
              >
                <div className={`${classes.input} ${classes.ib}`}>
                  <TextField
                    id="subdomainInput"
                    label="Site Location"
                    value={subdomain}
                    variant="outlined"
                    // inputProps={{
                    //   style: { textAlign: "right" },
                    // }}
                    InputProps={{
                      // endAdornment: (
                      startAdornment: (
                        // <InputAdornment position="end">.osdl.io</InputAdornment>
                        <InputAdornment position="start">
                          osdl.io/
                        </InputAdornment>
                      ),
                    }}
                    onChange={updateAndValidateSubdomain}
                    fullWidth
                    // onKeyPress={evaluateInput}
                  />
                </div>
                {/* <div className={`${classes.ib}`}>
                  <Typography>Text</Typography>
                </div> */}
              </div>
            </div>
          )}
          {activeStep === 1 && (
            <div className={`${classes.infoContainer}`}>
              <div className={`${classes.blockCenterFlex}`}>
                <div className={`${classes.input} ${classes.ib}`}>
                  <FormGroup className={`${classes.mb2} ${classes.ml1}`}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isCustomDomain}
                          onChange={() => setIsCustomDomain((prev) => !prev)}
                        />
                      }
                      label="I want to use a domain that I already own for this site"
                    />
                  </FormGroup>
                  {isCustomDomain && (
                    <>
                      <Typography
                        variant="subtitle1"
                        className={`${classes.mb2} ${classes.ml1} ${classes.italic}`}
                      >
                        What is the domain you are moving (e.g., example.com)?
                      </Typography>
                      <TextField
                        id="customDomainInput"
                        label="Custom Domain Name"
                        value={customDomainName}
                        variant="outlined"
                        fullWidth
                        onChange={(event) =>
                          setCustomDomainName(event.target.value)
                        }
                        onKeyUp={(event) => {
                          //workflow:  press enter, displays dialogue, which is conditioned on domain validity
                          //accept button in dialogue coded based on domain validity too
                          if (event.keyCode !== 13) {
                            return;
                          }

                          setDialogOpen(true);
                        }}
                      />
                      <Dialog
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                      >
                        <DialogTitle id="alert-dialog-title">
                          {isValidDomain(customDomainName)
                            ? "DNS Instructions"
                            : "Invalid Domain"}
                        </DialogTitle>
                        <DialogContent>
                          <Typography className={` ${classes.mb1}`}>
                            {isValidDomain(customDomainName) ? (
                              <>
                                Awesome! In order for your domain to work
                                properly, work with your DNS provider to set a{" "}
                                <span className={`${classes.italic}`}>
                                  CNAME entry pointing to{" "}
                                  <span className={`${classes.bold}`}>
                                    opensites.github.io.
                                  </span>
                                </span>
                              </>
                            ) : (
                              <>
                                Oops! It looks like your custom domain isn't
                                valid. Check for any typos and then try again.
                              </>
                            )}
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              setDialogOpen(false);
                              if (isValidDomain(customDomainName)) {
                                handleNext();
                              }
                            }}
                          >
                            Accept
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {activeStep === 2 && (
            // <Typography sx={{ mt: 2, mb: 1 }}>
            //   list that allows you to add, maybe comma separated, by email
            // </Typography>
            <div className={`${classes.infoContainer}`}>
              <div className={`${classes.blockCenterFlex}`}>
                <div className={`${classes.input} ${classes.ib}`}>
                  <TextField
                    id="editorInput"
                    label="Editor Emails"
                    value={nextEditor}
                    variant="outlined"
                    // inputProps={{
                    //   style: { textAlign: "right" },
                    // }}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">Email:</InputAdornment>
                    //   ),
                    // }}
                    fullWidth
                    onChange={updateNextEditor}
                    onKeyPress={validateAndAddEditor}
                  />
                </div>
              </div>
              {editors.map((email, index) => (
                <div
                  style={{ display: "inline-block" }}
                  key={`${email}-${index}`}
                >
                  <Chip
                    color="primary"
                    label={email}
                    onDelete={removeEditor(email)}
                    // avatar={
                    //   <Avatar alt={email} src={email} />
                    // }
                    className={classes.chip}
                  />
                </div>
              ))}
            </div>
          )}
          {activeStep === 3 && (
            <Typography sx={{ mt: 2, mb: 1 }}>
              When you are ready, click finish to deploy your site! Give about 5
              minutes, then your site should be live at{" "}
              <a href={`https://osdl.io/${subdomain}`}>osdl.io/{subdomain}</a>.
              <br />
              {isCustomDomain &&
                "Your custom domain will require your DNS settings to be changed before working."}
            </Typography>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button
              onClick={
                activeStep === steps.length - 1 ? handleFinish : handleNext
              }
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

const useStyles = (numLettersToExpand) => {
  return makeStyles((theme) => {
    const formMinWidth = 270;
    const formMaxWidth = Math.min(window.innerWidth, 900);

    const customResize = () => {
      return (
        formMinWidth -
        theme.spacing(1) +
        (theme.typography.fontSize + 1) * numLettersToExpand
      );
    };

    return {
      ib: {
        display: "inline-block",
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
      ml1: { marginLeft: theme.spacing(1) },
      bold: { fontWeight: "bold" },
      italic: { fontStyle: "italic" },
      input: {
        margin: theme.spacing(2),
        flexGrow: 1,
        flexShrink: 1,
      },
      textCenter: { textAlign: "center" },
      blockCenterFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        width: "56%",
        minWidth: formMinWidth - theme.spacing(1),
        maxWidth: "100%",
      },
      blockCenterFlexDynamicStepOne: {
        width: customResize() || "40%",
      },
      infoContainer: {
        width: "100%",
        minWidth: formMinWidth,
        maxWidth: formMaxWidth,
      },
      chip: {
        margin: theme.spacing(0.5),
        height: theme.spacing(3),
      },
    };
  })();
};
