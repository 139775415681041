//React and Apollo gql dependencies
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

//local imports
import { ScreenTemplate } from "../components/ScreenTemplate";
import { SightWordPallette } from "../components/SightWordPallette";
import { SightWordPhone } from "../components/SightWordPhone/SightWordPhone";

const useStyles = (windowSize) => {
  return makeStyles((theme) => {
    return {
      containerLarge: { display: "flex" },
      containerSmall: { textAlign: "center" },
      phone: { height: "100%" },
      phoneLarge: { marginRight: 20, marginLeft: 20 },
      phoneSmall: { margin: "auto", width: "80%" },
      palletteLarge: {
        overflow: "auto",
        maxHeight: "80vh",
        width: "80%",
        flex: 1,
        flexBasis: 0,
      },
      palletteSmall: {
        width: "80%",
        margin: "auto",
      },
    };
  })();
};

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const smallWidthBreakpoint = 700;
const smallHeightBreakpoint = 550;

export const SightWordsScreen = () => {
  const [draftMessage, setDraftMessage] = useState("");
  const [messages, setMessages] = useState([
    { isMine: false, content: "Hi! This is fun." },
    { isMine: true, content: "Yes it is." },
    { isMine: true, content: "So how does this work?" },
    { isMine: true, content: "Do we just send texts?" },
    { isMine: false, content: "Yep! And I answer!" },
  ]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const classes = useStyles(windowSize);

  const appendWord = (word) =>
    setDraftMessage((oldMessage) => (oldMessage + " " + word).trim());

  const removeLastWord = () =>
    setDraftMessage((oldMessage) => {
      const lastIndexSpace = oldMessage.lastIndexOf(" ");

      if (lastIndexSpace < 0) {
        return "";
      }

      return oldMessage.substring(0, lastIndexSpace).trim();
    });

  const levels = [6, 5, 4, 3, 2, 1];

  const isSmall = windowSize.innerWidth <= smallWidthBreakpoint;
  const isShort = windowSize.innerHeight <= smallHeightBreakpoint;

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <ScreenTemplate
        title="Silly or Serious? A Sight Words Adventure!"
        subtitle="Ask a robot a question using your sight words! Just click on words that you know to build a sentence and see what the robot says!"
        newSiteCta={false}
        smallMode={isSmall || isShort}
      >
        <div
          className={isSmall ? classes.containerSmall : classes.containerLarge}
        >
          {isSmall && (
            <div className={` ${classes.phone} ${classes.phoneSmall}`}>
              <SightWordPhone
                initialState={messages}
                draftMessage={draftMessage}
                onSendCallback={() => setDraftMessage("")}
                onAddCallback={setMessages}
                isShort={isShort}
              />
            </div>
          )}
          <div
            className={isSmall ? classes.palletteSmall : classes.palletteLarge}
          >
            {levels.map((level, index) => (
              <SightWordPallette
                key={`${level}-${index}`}
                style={{ marginBottom: 5 }}
                level={level}
                addWordCallback={appendWord}
                removeLastWordCallback={removeLastWord}
              />
            ))}
            <SightWordPallette
              style={{ marginBottom: 5 }}
              extras
              addWordCallback={appendWord}
              removeLastWordCallback={removeLastWord}
            />
          </div>
          {!isSmall && (
            <div className={` ${classes.phone} ${classes.phoneLarge}`}>
              <SightWordPhone
                initialState={messages}
                draftMessage={draftMessage}
                onSendCallback={() => setDraftMessage("")}
                onAddCallback={setMessages}
                isShort={isShort}
              />
            </div>
          )}
        </div>
      </ScreenTemplate>
    </>
  );
};
