//React and Apollo gql dependencies
import React from "react";
import { gql, useQuery } from "@apollo/client";

//material UI dependencies
import LinearProgress from "@material-ui/core/LinearProgress";

//local project dependencies
import LinksPage from "../../src/components/LinksPage";

//router dependencies
import { useParams } from "react-router-dom";

const CommunityLinksPage = () => {
  const { communityId } = useParams();
  const { loading, error, data } = useQuery(
    gql`
      query GetCommunityLinks($id: ID!) {
        getCommunityLinks(id: $id) {
          id
          linkText
          url
          updated
        }
      }
    `,
    { variables: { id: communityId } }
  );

  if (error) return `Error in CommunityLinksPage query! ${error.message}`;

  return (
    <>
      {loading && <LinearProgress />}
      {!loading && !error && (
        <LinksPage
          links={data.getCommunityLinks}
          refetchQuery="GetCommunityLinks"
          communityLinkId={communityId}
        />
      )}
    </>
  );
};

export default CommunityLinksPage;
