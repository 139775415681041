import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { alpha } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
// import { visuallyHidden } from "@material-ui/utils";

import { AdminRow } from "./AdminRow";
import { LoadingRow } from "./LoadingRow";
import { useGetAllSitesLazyQuery } from "../../util/api/Site/getAllSites";
import { useGetAdminSitesForCurrentUserLazyQuery } from "../../util/api/Site/getAdminSitesForCurrentUser";
import { useUpdateSitesLaunchStatusMutation } from "../../util/api/Site/updateSitesLaunchStatus";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "osdlURL",
    numeric: false,
    disablePadding: true,
    label: "Opensites URL",
  },
  {
    id: "pageStatus",
    numeric: false,
    disablePadding: false,
    label: "Page Status",
  },
  {
    id: "customDomain",
    numeric: false,
    disablePadding: false,
    label: "Custom Domain Status",
  },
  {
    id: "https",
    numeric: false,
    disablePadding: true,
    label: "HTTPS",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                // <Box component="span" sx={visuallyHidden}>
                <Box
                  component="span"
                  sx={{
                    visibility: "hidden",
                    width: 0,
                    height: 0,
                    overflow: "hidden",
                  }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({ numSelected, refreshData }) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(
        //       theme.palette.primary.main,
        //       theme.palette.action.activatedOpacity
        //     ),
        // }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          All opensites.io Sites
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refresh Data">
            <IconButton onClick={refreshData} sx={{ mr: 5 }}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export function SitesAppAdminTable({ currentUserOnly = true }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [viewSelectedOnly, setViewSelectedOnly] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    getAllSitesLazyQuery,
    getAllSitesIsLoading,
    getAllSitesHasError,
    getAllSitesData,
  } = useGetAllSitesLazyQuery();

  const {
    getAdminSitesForCurrentUserLazyQuery,
    getAdminSitesForCurrentUserIsLoading,
    getAdminSitesForCurrentUserHasError,
    getAdminSitesForCurrentUserData,
  } = useGetAdminSitesForCurrentUserLazyQuery();

  const { updateSitesLaunchStatus, updateSitesLaunchStatusIsLoading } =
    useUpdateSitesLaunchStatusMutation();

  // check if we are displaying data for current user only or site admin
  const sitesLazyQuery = currentUserOnly
    ? getAdminSitesForCurrentUserLazyQuery
    : getAllSitesLazyQuery;

  const sitesLoading = currentUserOnly
    ? getAdminSitesForCurrentUserIsLoading
    : getAllSitesIsLoading;

  const sitesError = currentUserOnly
    ? getAdminSitesForCurrentUserHasError
    : getAllSitesHasError;

  // add the data into the rows format, initialize as empty array as needed
  // check if all sites or just for that user
  const rows = currentUserOnly
    ? getAdminSitesForCurrentUserData?.getAdminSitesForCurrentUser || []
    : getAllSitesData?.getAllSites || [];

  // will autopopulate the data objects, no need to put into state
  // also will allow data to be updated by refetch queries
  useEffect(() => {
    sitesLazyQuery();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((row) => row.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleChangeViewSelectedOnly = (event) => {
    setViewSelectedOnly(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // rows to render based on the current page & number per page
  const renderRows = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  //refetch query? else put renderrows as state and have initializer
  const refreshData = () => {
    updateSitesLaunchStatus(
      renderRows.map((row) => ({
        id: row.id,
      }))
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Condense View"
      />
      <FormControlLabel
        control={
          <Switch
            checked={viewSelectedOnly}
            onChange={handleChangeViewSelectedOnly}
          />
        }
        label="View Selected Only"
      />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          refreshData={refreshData}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {sitesLoading || updateSitesLaunchStatusIsLoading ? (
                <LoadingRow numCol={6} dense={dense} />
              ) : (
                renderRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return !viewSelectedOnly || isItemSelected ? (
                    <AdminRow
                      row={row}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      onClick={(event) => handleClick(event, row.id)}
                      key={row.id}
                    />
                  ) : null;
                })
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
