// Import FirebaseAuth and firebase.
import React, { useContext } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebaseInstance } from "../util/firebase";

import { UserContext } from "../providers/UserProvider";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Card, CardContent, Typography, Button } from "@material-ui/core";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and GitHub as auth providers.
  signInOptions: [
    firebaseInstance.auth.EmailAuthProvider.PROVIDER_ID,
    firebaseInstance.auth.GoogleAuthProvider.PROVIDER_ID,
    firebaseInstance.auth.GithubAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

export const LoginScreen = () => {
  const [currentUser] = useContext(UserContext);

  return (
    <>
      {currentUser === undefined && <LinearProgress />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        {currentUser === null && (
          <Card
            style={{
              margin: "1em auto",
              borderRadius: "17px",
              backgroundColor: "#eeeeee",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                style={{ color: "gray", fontWeight: "bold" }}
              >
                Sign in
              </Typography>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebaseInstance.auth()}
              />
            </CardContent>
          </Card>
        )}
        {currentUser && (
          <Card
            style={{
              margin: "1em auto",
              borderRadius: "17px",
              backgroundColor: "#eeeeee",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                style={{ color: "gray", fontWeight: "bold" }}
              >
                Welcome! You are now signed-in!
              </Typography>
              <Button onClick={() => firebaseInstance.auth().signOut()}>
                <Typography
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  Sign-out
                </Typography>
              </Button>
            </CardContent>
          </Card>
        )}
      </div>
    </>
  );
};
