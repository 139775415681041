export const sightWords = [
  {
    level: 1,
    description: "Quarter 1 Pre-Primer",
    words: [
      "the",
      "to",
      "and",
      "a",
      "I",
      "you",
      "it",
      "in",
      "said",
      "for",
      "up",
      "look",
      "is",
      "go",
      "we",
      "little",
      "down",
      "can",
      "see",
      "not",
      "one",
      "my",
      "me",
      "big",
      "come",
    ],
  },
  {
    level: 2,
    description: "Quarter 2 Pre-Primer / Primer",
    words: [
      "blue",
      "red",
      "where",
      "jump",
      "away",
      "here",
      "help",
      "make",
      "yellow",
      "two",
      "play",
      "run",
      "find",
      "three",
      "funny",
      "he",
      "was",
      "that",
      "she",
      "on",
      "they",
      "but",
      "at",
      "with",
      "all",
    ],
  },
  {
    level: 3,
    description: "Quarter 3 Primer / First Grade",
    words: [
      "there",
      "out",
      "be",
      "have",
      "am",
      "do",
      "did",
      "what",
      "so",
      "get",
      "like",
      "this ",
      "will",
      "yes",
      "went",
      "are",
      "now",
      "no",
      "came",
      "ride",
      "into",
      "good",
      "want",
      "too",
      "pretty",
    ],
  },
  {
    level: 4,
    description: "Quarter 4 First Grade",
    words: [
      "four",
      "saw",
      "well",
      "ran",
      "brown",
      "eat",
      "who",
      "new",
      "must",
      "black",
      "white",
      "soon",
      "our",
      "ate",
      "say",
      "under",
      "please",
      "of",
      "his",
      "had",
      "him",
      "her",
      "some",
      "as",
      "then",
    ],
  },
  {
    level: 5,
    description: "Pattern: -ing (ask->asking, send->sending)",
    words: ["asking","sending","resting","fishing","thinking","singing"],
  },
  {
    level: 6,
    description: "Memory Words",
    words: ["there", "these", "they", "this", "time"],
  },
];

export const extraWords = {
  words: [
    "at",
    "cat",
    "hat",
    "bat",
    "rat",
    "mat",
    "sat",
    "chat",
    "pat",
    "that",
    "play",
    "stay",
    "day",
    "may",
    "say",
    "way",
    "ray",
    "pay",
    "lay",
    "key",
    "run",
    "fun",
    "sun",
    "nun",
    "bun",
    "son",
    "ton",
    "won",
    "pun",
    "dog",
    "log",
    "fog",
    "hog",
    "jog",
    "tog",
    "blog",
    "frog",
    "pig",
    "dig",
    "fig",
    "jig",
    "wig",
    "big",
    "rig",
    "twig",
    "prig",
  ],
};

// $30 table, smyrna
// $35 two drawer cabinet mableton (south of smyrna)
// $45 storage cabinet chamblee (north)
