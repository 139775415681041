//React and Apollo gql dependencies
import React from "react";
import SentimentDissatisfiedOutlinedIcon from "@material-ui/icons/SentimentDissatisfiedOutlined";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "center",
  },
  icon: {
    marginBottom: 10,
    marginTop: 20,
    color: theme.palette.secondary,
    fontSize: 300,
  },
  mainText: { paddingTop: 10 },
}));

export const PageNotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <SentimentDissatisfiedOutlinedIcon className={classes.icon} />
      <Typography
        className={classes.mainText}
        align="center"
        color="inherit"
        variant="h4"
      >
        Page Not Found
      </Typography>
    </div>
  );
};
