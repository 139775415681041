import React, { useState, useContext } from "react";
import { Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { red } from "@material-ui/core/colors";
import SendIcon from "@material-ui/icons/Send";
import BackspaceIcon from "@material-ui/icons/Backspace";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

import { getRoute } from "../util/routes";
import { sightWords, extraWords } from "../data/sightWords";

//image type is one of the public image titles. If null, undefined, or mispelled, it tries to pull from the
//site url, and if that fails it defaults to a basic image
export const SightWordPallette = ({
  level = 1,
  extras = false,
  addWordCallback = (word) => {},
  removeLastWordCallback = () => {},
}) => {
  const [message, setMessage] = useState("");

  const renderObject = extras
    ? extraWords
    : sightWords[level > 0 ? level - 1 : 0];

  const pageNotFoundLink = `${
    process.env.REACT_APP_EDUCATION_BASE_URL
  }${getRoute("notFound")}`;

  const trimTrailingSlash = (url) => {
    return url.slice(-1) === "/" ? url.slice(0, url.length - 1) : url;
  };

  return (
    <>
      <Card
        style={{
          maxWidth: 700,
          minWidth: 300,
          display: "inline-block",
          textAlign: "left",
        }}
      >
        <CardHeader
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={extras ? `Extra Words` : `Level ${renderObject.level}`}
          subheader={
            extras
              ? `Words that can be used to help at any level`
              : renderObject.description
          }
        />
        <CardContent>
          {renderObject.words.map((word, index) => (
            <Button
              style={{
                fontWeight: "bold",
                marginLeft: "auto",
                marginTop: "auto",
                marginRight: 10,
                marginBottom: 10,
                padding: 10,
              }}
              key={`${index}-${word}`}
              color="primary"
              variant="contained"
              onClick={() => addWordCallback(word)}
            >
              {word}
            </Button>
          ))}
        </CardContent>
        <CardActions disableSpacing>
          <Button
            style={{
              fontWeight: "bold",
              marginLeft: "auto",
              marginRight: 20,
            }}
            color="primary"
            variant="outlined"
            endIcon={<BackspaceIcon style={{ fontSize: 20 }} />}
            onClick={removeLastWordCallback}
          >
            Backspace
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
