import { gql, useMutation } from "@apollo/client";

export const useUpdateSitesLaunchStatusMutation = () => {
  const [
    updateSitesLaunchStatusMutation,
    {
      loading: updateSitesLaunchStatusIsLoading,
      error: updateSitesLaunchStatusHasError,
      data: updateSitesLaunchStatusData,
    },
  ] = useMutation(
    gql`
      mutation UpdateSitesLaunchStatus($sites: [SiteInput]!) {
        updateSitesLaunchStatus(sites: $sites) {
          id
          subdomain
          parentDomain
          primaryBaseURL
          isCustomDomain
          # siteTemplateID
          # admins {
          #   email
          #   lastLoggedIn
          # }
          # editors {
          #   email
          #   lastLoggedIn
          # }
          metadata {
            pageStatus
            pageLastLive
            createdAt
            httpsLastLive
            customDomainLastLive
            customDomainHttpsLastLive
            ogTitle
            ogDescription
            ogImage
          }
        }
      }
    `,
    {
      refetchQueries: ["GetAllSites"],
    }
  );

  // returns a promise
  const updateSitesLaunchStatus = (sites) => {
    updateSitesLaunchStatusMutation({
      variables: {
        sites,
      },
    })
      .then(({ data: { updateSitesLaunchStatus: newSites } }) => {
        if (Array.isArray(newSites)) {
          return newSites;
        }
      })
      .catch((err) =>
        console.log("Error in updateSitesLaunchStatusMutation: ", err)
      );
  };

  const updateSitesLaunchStatusThunk = (sites) => {
    return () => updateSitesLaunchStatus(sites);
  };

  return {
    updateSitesLaunchStatusMutation,
    updateSitesLaunchStatusIsLoading,
    updateSitesLaunchStatusHasError,
    updateSitesLaunchStatusData,
    updateSitesLaunchStatus,
    updateSitesLaunchStatusThunk,
  };
};
