//react imports
import React from "react";

//other imports
import { Switch, Route, Redirect } from "react-router-dom";

//local imports
import { SitesScreen } from "../screens/SitesScreen";
import { NewSiteScreen } from "../screens/NewSiteScreen";
import { SitesAppAdminScreen } from "../screens/SitesAppAdminScreen";
import { PageNotFound } from "../components/PageNotFound";
import { getRoute } from "../util/routes";

export const SitesRouter = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={getRoute("sites")} />
      </Route>
      <Route path={getRoute("sites_admin")}>
        <SitesAppAdminScreen currentUserOnly={true} />
      </Route>
      <Route path={getRoute("sites")}>
        <SitesScreen />
      </Route>
      <Route path={getRoute("newSite")}>
        <NewSiteScreen />
      </Route>
      <Route path={getRoute("sitesAppAdminPanel")}>
        <SitesAppAdminScreen currentUserOnly={false} />
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};
